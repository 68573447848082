import React, { createContext, useState } from 'react';

const initialState = {
  show: false,
  message: '',
  type: 'success',
};
export const NotificationProvider = ({ children }) => {
  const [showNotification, setShowNotification] = useState(initialState);
  return (
    <NotificationContext.Provider
      value={[showNotification, setShowNotification]}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const NotificationContext = createContext(initialState);
 