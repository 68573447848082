import { useSession } from "next-auth/client";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

export default function HeadComponent() {
  const [session] = useSession();
  const router = useRouter();
  let urlPath = router.asPath;

  const title = {
    "/": "Dashboard | DMB Canvass",
    "/contacts-and-groups": "Contacts | DMB Canvass",
    "/messages": "Messages | DMB Canvass",
    "/groups": "Groups | DMB Canvass",
    "/events": "Events | DMB Canvass",
    "/money": "Money | DMB Canvass",
    "/add-new-group": "Add New Group | DMB Canvass",
    "/create-contact": "Add New Contact | DMB Canvass",
    "/survey": "Surveys | DMB Canvass",
    "/admin": "Manage users | DMB Canvass",
    "/admin/add-user": "Add user | DMB Canvass",
    "/send-message": "Send Bulk Message | DMB Canvass",
    "/canvass": "Canvass | DMB Canvass",
    "/canvass/add-canvass": "Add Canvass | DMB Canvass",
    "/canvass/edit-canvass/": "Edit Canvass | DMB Canvass",
    "money/approvals": "Approvals | DMB Canvass",
    "money/send-money": "Send Money | DMB Canvass",
    "money/donations": "Donations | DMB Canvass",
    "dashboad/public-mood": "Public Mood | DMB Canvass",
  };

  return (
    <>
      <Script
        strategy="beforeInteractive"
        src="https://code.jquery.com/jquery-3.6.0.js"
      />

      <Head>
        <title>{title[urlPath] || "DMB Canvass"}</title>
        {urlPath === "/" && !session ? null : (
          <link rel="icon" href="/assets/images/dmb-canvas-logo.png" />
        )}
        <link
          rel="stylesheet"
          href="https://designs.dmb2024.com/assets/css/canvas.css"
        />
      </Head>
    </>
  );
}
